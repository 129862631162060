import React, { useEffect } from 'react';
import { Trash2, Clock, Users } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { removeIngredientsByMealEntry, updateIngredientPortions } from '../../utils/mealPlanUtils';
import { useAuthStore } from '../../store/useAuthStore';
import { addDays, startOfWeek, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

interface MealEntry {
  id: string;
  recipeId: string;
  recipeName: string;
  recipeImage?: string;
  date?: string;
  mealType?: string;
  portions?: number;
  notes?: string;
  completed?: boolean;
}

interface MealEntryCardProps {
  entry: MealEntry;
  onUpdate: (updates: Partial<MealEntry>) => void;
  onRemove: () => void;
  isArchived: boolean;
  isAutomationEnabled?: boolean;
}

export function MealEntryCard({ entry, onUpdate, onRemove, isArchived, isAutomationEnabled = false }: MealEntryCardProps) {
  const navigate = useNavigate();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const { currentMealPlan } = useMealPlanManagerStore();
  const currentUser = useAuthStore((state) => state.currentUser);

  // Find the associated recipe
  const recipe = React.useMemo(
    () => [...recipes, ...publicRecipes].find((r) => r.id === entry.recipeId),
    [entry.recipeId, recipes, publicRecipes]
  );

  // Automatically set default portions if undefined
  useEffect(() => {
    if (recipe && typeof entry.portions === 'undefined') {
      onUpdate({ portions: recipe.servings });
    }
  }, [recipe?.id]);

  // Generate days for the dropdown menu starting from the week's start date
  const days = React.useMemo(() => {
    const startOfWeekDate = startOfWeek(new Date(), { locale: fr });
    return Array.from({ length: 14 }, (_, i) => {
      const date = addDays(startOfWeekDate, i);
      return {
        value: format(date, 'yyyy-MM-dd'),
        label: format(date, 'EEE d MMM', { locale: fr })
      };
    });
  }, []);

  const mealTypes = [
    { value: 'breakfast', label: 'Petit-déjeuner' },
    { value: 'lunch', label: 'Déjeuner' },
    { value: 'snack', label: 'Goûter' },
    { value: 'dinner', label: 'Dîner' },
  ];

  const handlePortionChange = async (newPortions: number) => {
    if (!currentUser?.defaultOrganizationId || !entry.portions) return;

    try {
      // If automation is enabled, update grocery list portions first
      if (isAutomationEnabled) {
        await updateIngredientPortions(
          entry.id,
          currentUser.defaultOrganizationId,
          newPortions,
          entry.portions
        );
      }

      // Then update the meal entry
      onUpdate({ portions: newPortions });
    } catch (error) {
      console.error('Error updating portions:', error);
    }
  };

  const handleRemove = async () => {
    if (!currentUser?.defaultOrganizationId) return;

    try {
      // If automation is enabled, remove ingredients first
      if (isAutomationEnabled) {
        await removeIngredientsByMealEntry(entry.id, currentUser.defaultOrganizationId);
      }
      
      // Then remove the meal entry
      onRemove();
    } catch (error) {
      console.error('Error removing meal entry:', error);
    }
  };

  const handleRecipeClick = () => {
    if (entry.recipeId) {
      navigate(`/recipes/${entry.recipeId}`);
    }
  };

  return (
    <div className="group bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-all">
      <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
        {/* Recipe Image */}
        {recipe?.image && (
          <img
            src={recipe.image}
            alt={entry.recipeName}
            className="w-full sm:w-16 h-32 sm:h-16 rounded-md object-cover cursor-pointer"
            onClick={handleRecipeClick}
          />
        )}

        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <h3 
              className="font-medium text-gray-900 truncate cursor-pointer hover:text-menioo-green"
              onClick={handleRecipeClick}
            >
              {entry.recipeName}
            </h3>
            {!isArchived && (
              <button
                onClick={handleRemove}
                className="p-1.5 text-red-500 hover:bg-red-50 rounded-md transition-all"
                title="Remove recipe"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            )}
          </div>

          {!isArchived && (
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
              {/* Portions Input */}
              <div>
                <label className="block text-xs text-gray-500 mb-1">Portions</label>
                <input
                  type="number"
                  min="1"
                  value={entry.portions || ''}
                  onChange={(e) => handlePortionChange(Number(e.target.value))}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green text-sm"
                />
              </div>

              {/* Day Selector */}
              <div>
                <label className="block text-xs text-gray-500 mb-1">Jour</label>
                <select
                  value={entry.date || ''}
                  onChange={(e) => onUpdate({ date: e.target.value })}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green text-sm"
                >
                  <option value="">Choisir un jour</option>
                  {days.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Meal Type Selector */}
              <div>
                <label className="block text-xs text-gray-500 mb-1">Type de repas</label>
                <select
                  value={entry.mealType || ''}
                  onChange={(e) => onUpdate({ mealType: e.target.value })}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green text-sm"
                >
                  <option value="">Choisir un type</option>
                  {mealTypes.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
