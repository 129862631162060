import React, { useState } from 'react';
import { Clock, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Recipe } from '../../types';
import { useCookbookStore } from '../../store/useCookbookStore';
import { useAuthStore } from '../../store/useAuthStore';
import { PlannerButton } from './PlannerButton';
import { HeartButton } from './HeartButton';
import { addRecipeToMealPlanAndGrocery } from '../../utils/mealPlanUtils';

// Default image URL for recipes without images
const DEFAULT_RECIPE_IMAGE = "https://lh3.googleusercontent.com/d/1ej0k5VtVASrLCTiLR-6tqXHU4400jk9T";

interface RecipeCardProps {
  recipe: Recipe;
  isDiscoveryPage?: boolean;
  canEdit?: boolean;
  onDelete?: (id: string) => Promise<void>;
  onAddToMealPlan?: (recipe: Recipe) => Promise<void>;
}

export function RecipeCard({ recipe, isDiscoveryPage = false, canEdit = false, onDelete, onAddToMealPlan }: RecipeCardProps) {
  const [plannerError, setPlannerError] = useState<string | null>(null);

  const isInCookbook = useCookbookStore(
    (state) => state.publicRecipeIds.includes(recipe.id)
  );
  const addToCookbook = useCookbookStore((state) => state.addToCookbook);
  const removeFromCookbook = useCookbookStore((state) => state.removeFromCookbook);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const currentUser = useAuthStore((state) => state.currentUser);

  const isOwnRecipe = () => {
    if (!currentUser || !currentUser.defaultOrganizationId) return false;
    return recipe.organizationId === currentUser.defaultOrganizationId;
  };

  const handleAddToMealPlan = async (e: React.MouseEvent) => {
    e.preventDefault();
    setPlannerError(null);

    if (!currentUser?.defaultOrganizationId) {
      setPlannerError('Please log in to add recipes to your meal plan');
      return;
    }

    try {
      await addRecipeToMealPlanAndGrocery(
        recipe,
        '',
        '',
        currentUser.id,
        currentUser.defaultOrganizationId
      );

      if (!isInCookbook && recipe.organizationId !== currentUser.defaultOrganizationId) {
        await addToCookbook(recipe.id);
      }
    } catch (error) {
      console.error('Error adding to meal plan or cookbook:', error);
      setPlannerError('Failed to add recipe to meal plan and cookbook');
      throw error;
    }
  };

  const handleToggleBookmark = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      if (isInCookbook) {
        await removeFromCookbook(recipe.id);
      } else {
        await addToCookbook(recipe.id);
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };

  const recipeImage = recipe.image || DEFAULT_RECIPE_IMAGE;

  return (
    <div className="relative group rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-200">
      {plannerError && (
        <div className="absolute top-0 left-0 right-0 bg-red-50 text-red-600 text-sm px-4 py-2 z-10">
          {plannerError}
        </div>
      )}

      <Link to={`/recipes/${recipe.id}`} className="block relative aspect-[4/3]">
        <img
          src={recipeImage}
          alt={recipe.name}
          className="w-full h-full object-cover"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null; // Prevent infinite loop
            target.src = DEFAULT_RECIPE_IMAGE;
          }}
        />

        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

        <div className="absolute inset-x-0 bottom-0 p-4 text-white">
          <h3 className="text-lg font-medium leading-tight mb-2 line-clamp-2">
            {recipe.name}
          </h3>
          <div className="flex items-center space-x-4 text-sm">
            <div className="flex items-center">
              <Clock className="h-4 w-4 mr-1" />
              <span>{recipe.prepTime + recipe.cookTime} mins</span>
            </div>
            <div className="flex items-center">
              <Users className="h-4 w-4 mr-1" />
              <span>{recipe.servings}</span>
            </div>
          </div>
        </div>

        <div className="absolute top-2 right-2 flex items-center space-x-2">
          {isAuthenticated && (
            <PlannerButton
              onClick={handleAddToMealPlan}
              className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
            />
          )}
        </div>

        {isAuthenticated && !isOwnRecipe() && (
          <HeartButton
            recipeId={recipe.id}
            isActive={isInCookbook}
            onClick={handleToggleBookmark}
            className="absolute top-2 left-2"
            initialCount={recipe.bookmarkCount || 0}
          />
        )}
      </Link>
    </div>
  );
}