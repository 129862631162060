import React, { useRef, useEffect, forwardRef } from 'react';
import { MinusCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { StandardIngredient } from '../../types/ingredients';
import { IngredientSelector } from './IngredientSelector';
import { standardUnits } from '../../types/units';

interface IngredientEntryProps {
  value: {
    id: string;
    standardIngredientId: string;
    name: string;
    category: string;
    quantity: number;
    unit: string;
  };
  onChange: (updates: Partial<typeof value>) => void;
  onRemove: () => void;
  onAddNew: () => void;
  autoFocus?: boolean;
  isLast?: boolean;
}

export const IngredientEntry = forwardRef<HTMLDivElement, IngredientEntryProps>(({
  value,
  onChange,
  onRemove,
  onAddNew,
  autoFocus = false,
  isLast = false
}, ref) => {
  const { t } = useTranslation();
  const ingredientSelectorRef = useRef<HTMLDivElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const unitRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (autoFocus) {
      // Focus the search input within IngredientSelector
      const searchInput = ingredientSelectorRef.current?.querySelector('input[type="text"]');
      if (searchInput instanceof HTMLInputElement) {
        setTimeout(() => searchInput.focus(), 0);
      }
    }
  }, [autoFocus]);

  const handleIngredientSelect = (ingredient: StandardIngredient) => {
    onChange({
      standardIngredientId: ingredient.id,
      name: ingredient.name,
      category: ingredient.category,
      unit: ingredient.preferredUnit
    });
    quantityRef.current?.focus();
  };

  const handleQuantityKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
      e.preventDefault();
      unitRef.current?.focus();
    }
  };

const handleUnitKeyDown = (e: React.KeyboardEvent<HTMLSelectElement>) => {
  if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
    e.preventDefault();
    if (isLast) {
      onAddNew();
      setTimeout(() => {
        const ingredients = document.querySelectorAll('.ingredient-entry');
        const lastIngredient = ingredients[ingredients.length - 1];
        const searchDiv = lastIngredient?.querySelector('.ingredient-search-div');
        if (searchDiv) {
          searchDiv.click(); // This will trigger the search dropdown to open
        }
      }, 100);
    }
  }
};

  return (
    <div 
      ref={ref}
      className="flex gap-3 items-center p-2 rounded-lg hover:bg-gray-50 transition-colors ingredient-entry"
    >
      <div className="flex-1" ref={ingredientSelectorRef}>
        <IngredientSelector
          value={value}
          onChange={handleIngredientSelect}
          className="w-full"
        />
      </div>

      <div className="w-32">
        <input
          ref={quantityRef}
          type="number"
          value={value.quantity === 0 ? '' : value.quantity}
          onChange={(e) => onChange({ quantity: e.target.value === '' ? 0 : Number(e.target.value) })}
          onKeyDown={handleQuantityKeyDown}
          step="1"
          className="w-full h-10 px-3 rounded-md border border-gray-300 shadow-sm focus:border-menioo-green focus:ring-1 focus:ring-menioo-green transition-colors"
          placeholder="Quantité"
        />
      </div>
      
      <div className="w-40">
        <select
          ref={unitRef}
          value={value.unit}
          onChange={(e) => onChange({ unit: e.target.value })}
          onKeyDown={handleUnitKeyDown}
          className="w-full h-10 px-3 rounded-md border border-gray-300 shadow-sm focus:border-menioo-green focus:ring-1 focus:ring-menioo-green transition-colors appearance-none bg-white"
        >
          <option value="">Unité</option>
          {Object.entries(standardUnits).map(([unit, translation]) => (
            <option key={unit} value={unit}>
              {t(`units.${unit}`)}
            </option>
          ))}
        </select>
      </div>

      <button
        type="button"
        onClick={onRemove}
        className="flex-shrink-0 h-10 w-10 flex items-center justify-center text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors"
        title="Remove ingredient"
      >
        <MinusCircle className="h-5 w-5" />
      </button>
    </div>
  );
});

IngredientEntry.displayName = 'IngredientEntry';