import React from 'react';
import { MainNavigation } from '../main/MainNavigation';
import { Link } from 'react-router-dom';

export function WhyMenioo() {
  return (
    <div className="min-h-screen bg-menioo-cream">
      <MainNavigation />
      
      <main className="max-w-4xl mx-auto px-4 py-8 bg-white shadow-sm rounded-lg my-8">
        <style>{`
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }
          
          .fade-in {
            opacity: 0;
            animation: fadeIn 0.8s ease-out forwards;
          }
          
          .cta-button {
            display: inline-block;
            padding: 12px 24px;
            background-color: #FF9800;
            color: white;
            text-decoration: none;
            border-radius: 6px;
            font-weight: 600;
            transition: all 0.3s ease;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          
          .cta-button:hover {
            background-color: #F57C00;
            box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
            transform: translateY(-2px);
          }
        `}</style>
        
        <section className="fade-in mb-12" style={{animationDelay: '0.1s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Qu'est-ce que Menioo ?</h2>
          <p className="text-gray-700 mb-3">Gagner du temps en cuisine, mieux organiser ses repas et simplifier ses courses : c'est la promesse de Menioo ! Conçue pour vous aider à planifier vos repas facilement, cette application vous permet de créer automatiquement votre liste de courses et de mieux gérer votre alimentation.</p>
          <p className="text-gray-700">Menioo est aussi une <strong>communauté</strong> où chacun peut partager ses propres recettes et découvrir celles des autres.</p>
        </section>

        <section className="fade-in mb-12" style={{animationDelay: '0.2s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Pourquoi choisir Menioo ?</h2>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-2xl mr-3">🥘</span>
              <span><strong className="text-menioo-navy">Ajoutez et partagez vos propres recettes</strong> – Contribuez à une base de données de recettes enrichie par les utilisateurs.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">📋</span>
              <span><strong className="text-menioo-navy">Planification simplifiée</strong> – Organisez vos repas en fonction de votre emploi du temps.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🛒</span>
              <span><strong className="text-menioo-navy">Liste de courses automatique</strong> – Générez votre liste en un clic, sans oublier d'ingrédients.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🤖</span>
              <span><strong className="text-menioo-navy">IA intelligente</strong> – Profitez de suggestions basées sur votre régime alimentaire et les promotions en magasin.</span>
            </li>
          </ul>
        </section>

        <section className="fade-in mb-12" style={{animationDelay: '0.3s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Comment fonctionne Menioo ?</h2>
          <ol className="space-y-4 list-decimal pl-5">
            <li className="pl-2">
              <strong className="text-menioo-navy">Ajoutez vos propres recettes</strong> ou explorez celles de la communauté.
            </li>
            <li className="pl-2">
              <strong className="text-menioo-navy">Générez votre liste de courses</strong> en un clic.
            </li>
            <li className="pl-2">
              <strong className="text-menioo-navy">Faites vos courses et cuisinez !</strong> Utilisez votre liste en magasin ou synchronisez-la avec un service de livraison.
            </li>
          </ol>
        </section>

        <section className="fade-in mb-12" style={{animationDelay: '0.4s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Menioo : Une communauté engagée</h2>
          <p className="text-gray-700">
            Menioo, ce n'est pas seulement une application, c'est aussi une communauté d'utilisateurs qui partagent leurs meilleures recettes, astuces et idées pour une alimentation variée et équilibrée. Chaque utilisateur peut contribuer et enrichir la plateforme en partageant ses propres créations culinaires.
          </p>
        </section>

        <section className="fade-in mb-12" style={{animationDelay: '0.5s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Menioo est fait pour vous si...</h2>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-2xl mr-3">👨‍👩‍👧‍👦</span>
              <span>Vous êtes une famille qui veut mieux organiser ses repas.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">👩‍💻</span>
              <span>Vous êtes un professionnel débordé cherchant à gagner du temps.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🌱</span>
              <span>Vous suivez un régime spécifique et cherchez des suggestions adaptées.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🍽️</span>
              <span>Vous aimez partager vos recettes et découvrir celles des autres.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🌍</span>
              <span>Vous voulez réduire le gaspillage alimentaire.</span>
            </li>
          </ul>
        </section>

        <section className="fade-in text-center mb-12" style={{animationDelay: '0.6s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Essayez Menioo dès aujourd'hui !</h2>
          <p className="text-gray-700 mb-6">Menioo est disponible gratuitement et vous aide à simplifier votre quotidien dès maintenant.</p>
          <p>
            <Link to="/signup" className="cta-button">
              Inscrivez-vous gratuitement
            </Link>
          </p>
        </section>

        <footer className="fade-in border-t border-gray-200 pt-6 mt-8" style={{animationDelay: '0.7s'}}>
          <p className="text-center text-gray-500">&copy; 2025 Menioo. Tous droits réservés.</p>
        </footer>
      </main>
    </div>
  );
}