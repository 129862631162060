import { create } from 'zustand';
import { 
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Cookbook } from '../types';
import { useAuthStore } from './useAuthStore';

interface CookbookStore {
  publicRecipeIds: string[];
  searchTerm: string;
  isLoading: boolean;
  error: string | null;
  setSearchTerm: (term: string) => void;
  addToCookbook: (recipeId: string) => Promise<void>;
  removeFromCookbook: (recipeId: string) => Promise<void>;
  isInCookbook: (recipeId: string) => boolean;
  initializeCookbookSubscription: () => () => void;
  cleanup: () => void;
}

export const useCookbookStore = create<CookbookStore>((set, get) => ({
  publicRecipeIds: [],
  searchTerm: '',
  isLoading: false,
  error: null,

  setSearchTerm: (term: string) => {
    set({ searchTerm: term });
  },

  addToCookbook: async (recipeId: string) => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) {
      set({ error: 'User not authenticated' });
      return;
    }

    // Optimistically update state
    set(state => ({
      publicRecipeIds: [...state.publicRecipeIds, recipeId],
      isLoading: true,
      error: null
    }));

    try {
      const cookbookRef = doc(db, 'cookbooks', currentUser.defaultOrganizationId);
      const cookbookDoc = await getDoc(cookbookRef);

      // Verify organization access
      if (cookbookDoc.exists() && cookbookDoc.data().organizationId !== currentUser.defaultOrganizationId) {
        throw new Error('Unauthorized access to cookbook');
      }

      if (!cookbookDoc.exists()) {
        // Create new cookbook
        await setDoc(cookbookRef, {
          organizationId: currentUser.defaultOrganizationId,
          publicRecipeIds: [recipeId],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      } else {
        // Update existing cookbook
        await updateDoc(cookbookRef, {
          publicRecipeIds: arrayUnion(recipeId),
          updatedAt: serverTimestamp()
        });
      }
    } catch (error) {
      // Revert optimistic update on error
      set(state => ({
        publicRecipeIds: state.publicRecipeIds.filter(id => id !== recipeId),
        error: error instanceof Error ? error.message : 'Failed to add recipe to cookbook',
        isLoading: false
      }));
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  removeFromCookbook: async (recipeId: string) => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) {
      set({ error: 'User not authenticated' });
      return;
    }

    // Optimistically update state
    set(state => ({
      publicRecipeIds: state.publicRecipeIds.filter(id => id !== recipeId),
      isLoading: true,
      error: null
    }));

    try {
      const cookbookRef = doc(db, 'cookbooks', currentUser.defaultOrganizationId);
      const cookbookDoc = await getDoc(cookbookRef);

      // Verify organization access
      if (!cookbookDoc.exists()) {
        throw new Error('Cookbook not found');
      }

      if (cookbookDoc.data().organizationId !== currentUser.defaultOrganizationId) {
        throw new Error('Unauthorized access to cookbook');
      }

      await updateDoc(cookbookRef, {
        publicRecipeIds: arrayRemove(recipeId),
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      // Revert optimistic update on error
      set(state => ({
        publicRecipeIds: [...state.publicRecipeIds, recipeId],
        error: error instanceof Error ? error.message : 'Failed to remove recipe from cookbook',
        isLoading: false
      }));
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  isInCookbook: (recipeId: string) => {
    return get().publicRecipeIds.includes(recipeId);
  },

  initializeCookbookSubscription: () => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) {
      set({ 
        publicRecipeIds: [],
        error: 'No organization selected',
        isLoading: false 
      });
      return () => {};
    }

    const cookbookQuery = query(
      collection(db, 'cookbooks'),
      where('organizationId', '==', currentUser.defaultOrganizationId)
    );

    const unsubscribe = onSnapshot(
      cookbookQuery,
      (snapshot) => {
        if (!snapshot.empty) {
          const cookbook = snapshot.docs[0].data() as Cookbook;
          
          // Verify organization access
          if (cookbook.organizationId !== currentUser.defaultOrganizationId) {
            set({ 
              error: 'Unauthorized access to cookbook',
              publicRecipeIds: [],
              isLoading: false 
            });
            return;
          }

          set({
            publicRecipeIds: cookbook.publicRecipeIds || [],
            error: null,
            isLoading: false
          });
        } else {
          set({
            publicRecipeIds: [],
            error: null,
            isLoading: false
          });
        }
      },
      (error) => {
        console.error('Error in cookbook subscription:', error);
        set({ 
          error: error.message,
          isLoading: false 
        });
      }
    );

    return () => {
      unsubscribe();
      get().cleanup();
    };
  },

  cleanup: () => {
    set({
      publicRecipeIds: [],
      searchTerm: '',
      error: null,
      isLoading: false
    });
  }
}));
