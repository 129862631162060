import React from 'react';
import { X } from 'lucide-react';
import { Recipe, MealType } from '../../types';
import { ModalRecipeCard } from './ModalRecipeCard';

interface DateOption {
  value: string;
  label: string;
}

interface MealTypeModalProps {
  recipe: Recipe;
  onClose: () => void;
  onSelect: (mealType: MealType) => void;
  onSkip?: () => void;
  isOpen: boolean;
  selectedDate?: string;
  onDateChange?: (date: string) => void;
  source?: 'calendar' | 'draggable';
  dates?: DateOption[];
}

const MEAL_TYPES = [
  { type: 'breakfast' as MealType, label: 'Petit-déjeuner', icon: '🍳' },
  { type: 'lunch' as MealType, label: 'Déjeuner', icon: '🍝' },
  { type: 'snack' as MealType, label: 'Goûter', icon: '🍪' },
  { type: 'dinner' as MealType, label: 'Dîner', icon: '🍖' }
];

export function MealTypeModal({ 
  recipe, 
  onClose, 
  onSelect, 
  onSkip,
  isOpen,
  selectedDate,
  onDateChange,
  source = 'calendar',
  dates = []
}: MealTypeModalProps) {
  if (!isOpen || !recipe) return null;

  const handleSubmit = (type: MealType) => {
    if (source === 'draggable' && !selectedDate) {
      alert('Please select a date first');
      return;
    }
    onSelect(type);
  };

  const handleSkip = () => {
    if (source === 'draggable' && !selectedDate) {
      alert('Please select a date first');
      return;
    }
    if (onSkip) onSkip();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-[60]">
      <div className="bg-white rounded-lg w-full max-w-lg">
        <div className="p-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-medium text-menioo-navy">
            {source === 'calendar' ? 'Type de repas' : 'Ajouter au planning'}
          </h3>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4">
          <div className="mb-6">
            <ModalRecipeCard recipe={recipe} />
          </div>

          {/* Date Selection - Only show if coming from draggable recipe */}
          {source === 'draggable' && onDateChange && (
            <div className="mb-6">
              <label className="block text-sm font-medium text-menioo-navy mb-2">
                Choisir un jour
              </label>
              <select
                value={selectedDate}
                onChange={(e) => onDateChange(e.target.value)}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              >
                <option value="">Sélectionner un jour</option>
                {dates.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="grid grid-cols-2 gap-2 mb-4">
            {MEAL_TYPES.map(({ type, label, icon }) => (
              <button
                key={type}
                onClick={() => handleSubmit(type)}
                disabled={source === 'draggable' && !selectedDate}
                className={`p-4 text-center bg-white border border-gray-200 rounded-lg transition-colors ${
                  source === 'draggable' && !selectedDate
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:border-menioo-green hover:bg-menioo-green/5'
                }`}
              >
                <span className="text-2xl mb-2 block">{icon}</span>
                <span className="font-medium text-sm text-menioo-navy">{label}</span>
              </button>
            ))}
          </div>

          {onSkip && (
            <button
              onClick={handleSkip}
              disabled={source === 'draggable' && !selectedDate}
              className={`w-full py-3 px-4 text-gray-600 border border-gray-200 rounded-md transition-colors text-sm font-medium ${
                source === 'draggable' && !selectedDate
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-gray-50 hover:border-gray-300'
              }`}
            >
              Ajouter sans type de repas
            </button>
          )}
        </div>
      </div>
    </div>
  );
}