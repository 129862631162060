import React, { useState, useRef, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { StandardIngredient } from '../../types/ingredients';
import { standardUnits } from '../../types/units';
import { GroceryItemSelector } from './GroceryItemSelector';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { useAuthStore } from '../../store/useAuthStore';
import { addDoc, collection, getDocs, query, where, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';

interface AddCustomItemProps {
  onAdd: (item: {
    name: string;
    quantity: number;
    unit: string;
    category: string;
    standardIngredientId?: string;
  }) => Promise<void>;
}

export function AddCustomItem({ onAdd }: AddCustomItemProps) {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [customName, setCustomName] = useState('');
  const [selectedIngredient, setSelectedIngredient] = useState<StandardIngredient | null>(null);
  const [selectedUnit, setSelectedUnit] = useState('piece');
  const currentUser = useAuthStore((state) => state.currentUser);
  const { currentList } = useGroceryListStore();

  // Refs for form elements
  const ingredientSelectorRef = useRef<HTMLDivElement>(null);
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const unitSelectRef = useRef<HTMLSelectElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  // Focus ingredient selector when form opens
  useEffect(() => {
    if (isAdding) {
      const searchInput = ingredientSelectorRef.current?.querySelector('input[type="text"]');
      if (searchInput instanceof HTMLInputElement) {
        setTimeout(() => searchInput.focus(), 0);
      }
    }
  }, [isAdding]);

  const handleIngredientSelect = (ingredient: StandardIngredient) => {
    setSelectedIngredient(ingredient);
    setCustomName(ingredient.name);
    setSelectedUnit(ingredient.preferredUnit);
    // Focus quantity input after selecting ingredient
    setTimeout(() => quantityInputRef.current?.focus(), 0);
  };

  const handleCustomNameChange = (name: string) => {
    setCustomName(name);
    // If we're setting a custom name, clear the selected ingredient
    if (selectedIngredient?.name !== name) {
      setSelectedIngredient(null);
    }
  };

  const handleQuantityKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
      e.preventDefault();
      unitSelectRef.current?.focus();
    }
  };

  const handleUnitKeyDown = (e: React.KeyboardEvent<HTMLSelectElement>) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
      e.preventDefault();
      addButtonRef.current?.focus();
    }
  };

  const createNewGroceryList = async () => {
    if (!currentUser?.defaultOrganizationId) return null;

    const startDate = startOfWeek(new Date(), { locale: fr });
    const endDate = endOfWeek(startDate, { locale: fr });

    const newList = {
      name: `Liste de courses du ${format(startDate, 'd MMMM', { locale: fr })}`,
      organizationId: currentUser.defaultOrganizationId,
      status: 'active',
      items: [],
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };

    const docRef = await addDoc(collection(db, 'groceryLists'), newList);
    return docRef.id;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if ((!selectedIngredient?.name && !customName) || !currentUser?.defaultOrganizationId || !quantity) return;

    try {
      // Check if we need to create a new grocery list
      if (!currentList) {
        await createNewGroceryList();
      }

      const itemData = {
        name: customName || selectedIngredient?.name || '',
        quantity: Math.round(Number(quantity)),
        unit: selectedUnit || 'piece',
        category: selectedIngredient?.category || 'other',
        standardIngredientId: selectedIngredient?.id || ''
      };

      await onAdd(itemData);
      
      // Reset form
      setQuantity('');
      setCustomName('');
      setSelectedIngredient(null);
      setSelectedUnit('piece');
      setIsAdding(false);
    } catch (error) {
      console.error('Error adding custom item:', error);
    }
  };

  if (!isAdding) {
    return (
      <button
        onClick={() => setIsAdding(true)}
        className="flex items-center space-x-2 text-menioo-green hover:text-menioo-green-dark transition-colors"
      >
        <Plus className="h-4 w-4" />
        <span>Ajouter un article</span>
      </button>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-menioo-navy mb-1">
            Article
          </label>
          <div ref={ingredientSelectorRef}>
            <GroceryItemSelector
              value={{ name: selectedIngredient?.name || customName, unit: selectedUnit || selectedIngredient?.preferredUnit || '' }}
              onChange={handleIngredientSelect}
              onCustomInputChange={handleCustomNameChange}
              className="w-full"
            />
          </div>
        </div>
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-xs text-gray-500 mb-1">
              Quantité
            </label>
              <input
                ref={quantityInputRef}
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                onKeyDown={handleQuantityKeyDown}
                required
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              />
          </div>

          <div>
            <label className="block text-xs text-gray-500 mb-1">
              Unité
            </label>
            <select
              ref={unitSelectRef}
              value={selectedUnit}
              onChange={(e) => setSelectedUnit(e.target.value)}
              onKeyDown={handleUnitKeyDown}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
            >
              {Object.entries(standardUnits).map(([unit, translation]) => (
                <option key={unit} value={unit}>
                  {t(`units.${unit}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-end space-x-2">
        <button
          type="button"
          onClick={() => setIsAdding(false)}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Annuler
        </button>
        <button
          ref={addButtonRef}
          type="submit"
          disabled={(!selectedIngredient && !customName) || !quantity}
          className="px-4 py-2 text-sm font-medium text-white bg-menioo-green rounded-md hover:bg-menioo-green-dark disabled:opacity-50"
        >
          Ajouter
        </button>
      </div>
    </form>
  );
}