import { collection, doc, query, where, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, startOfWeek, addDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { useState, useMemo, useEffect } from 'react';
import { Search, Filter, X, ChevronLeft, ChevronRight, ShoppingBasket } from 'lucide-react';
import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import { useStore } from '../../store/useStore';
import { useAuthStore } from '../../store/useAuthStore';
import { useCookbookStore } from '../../store/useCookbookStore';
import { Recipe } from '../../types';
import { addRecipeToMealPlanAndGrocery, addRecipeIngredientsManually } from '../../utils/mealPlanUtils';
import { AutomationToggle } from './AutomationToggle';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';

import { DraggableRecipe } from './DraggableRecipe';
import { MealSectionCalendar } from './MealSectionCalendar';
import { GroceryListSidebar } from './GroceryListSidebar';

export function PlanningViewV3() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isGroceryListOpen, setIsGroceryListOpen] = useState(false);
  const [isAutomationEnabled, setIsAutomationEnabled] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isUserToggling, setIsUserToggling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState(() => startOfWeek(new Date(), { locale: fr }));
  const [selectedFilters, setSelectedFilters] = useState({
    vegetarian: false,
    vegan: false,
    glutenFree: false,
    difficulty: 'all',
    prepTime: 'all'
  });

  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);
  const currentUser = useAuthStore((state) => state.currentUser);
  const { currentMealPlan, createNewMealPlan, updateMealPlan, initializeSubscription } = useMealPlanManagerStore();
  const [isToggling, setIsToggling] = useState(false);

  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));

  // Get all available recipes with filters
  const allRecipes = useMemo(() => {
    const cookbookRecipes = [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];

    return cookbookRecipes.filter(recipe => {
      // Apply dietary filters
      if (selectedFilters.vegetarian && !recipe.classification.dietaryRestrictions.includes('vegetarian')) {
        return false;
      }
      if (selectedFilters.vegan && !recipe.classification.dietaryRestrictions.includes('vegan')) {
        return false;
      }
      if (selectedFilters.glutenFree && !recipe.classification.dietaryRestrictions.includes('glutenFree')) {
        return false;
      }

      // Apply difficulty filter
      if (selectedFilters.difficulty !== 'all' && recipe.classification.difficulty !== selectedFilters.difficulty) {
        return false;
      }

      // Apply prep time filter
      if (selectedFilters.prepTime !== 'all') {
        const totalTime = recipe.prepTime + recipe.cookTime;
        switch (selectedFilters.prepTime) {
          case 'quick':
            if (totalTime > 30) return false;
            break;
          case 'medium':
            if (totalTime <= 30 || totalTime > 60) return false;
            break;
          case 'long':
            if (totalTime <= 60) return false;
            break;
        }
      }

      return true;
    });
  }, [recipes, publicRecipes, publicRecipeIds, selectedFilters]);

  // Filter recipes for the sidebar only
  const sidebarRecipes = useMemo(() => {
    if (!searchTerm) return allRecipes;
    
    return allRecipes.filter(recipe => 
      recipe.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [allRecipes, searchTerm]);

  // Initialize subscription
  useEffect(() => {
    const unsubscribe = initializeSubscription();
    return () => unsubscribe();
  }, [initializeSubscription]);

  // Initialize automation state from meal plan
  useEffect(() => {
    if (currentMealPlan?.automationExpiration) {
      const isAutomated = new Date(currentMealPlan.automationExpiration) > new Date();
      setIsAutomationEnabled(isAutomated);
    } else {
      setIsAutomationEnabled(false);
    }
  }, [currentMealPlan?.automationExpiration]);

  const handleQuickAdd = async (recipe: Recipe) => {
    if (!currentUser?.defaultOrganizationId) return;

    try {
      await addRecipeToMealPlanAndGrocery(
        recipe,
        '', // Empty date for unplanned meals
        '', // Empty meal type for unplanned meals
        currentUser.id,
        currentUser.defaultOrganizationId,
        recipe.servings
      );
    } catch (error) {
      console.error('Error adding recipe:', error);
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || !currentUser?.defaultOrganizationId || !currentMealPlan) return;
    if (destination.droppableId === 'recipe-grid') return;

    setIsLoading(true);

    try {
      if (source.droppableId === 'recipe-grid') {
        // Dragging from recipe grid to meal plan
        const recipe = allRecipes.find(r => r.id === draggableId);
        if (!recipe) return;

        let destDate = '';
        let destMealType = '';

        if (destination.droppableId !== 'unplanned-recipes') {
          [destDate, destMealType] = destination.droppableId.split('_');
        }

        // Use addRecipeToMealPlanAndGrocery which handles both meal plan and grocery list updates
        await addRecipeToMealPlanAndGrocery(
          recipe,
          destDate,
          destMealType,
          currentUser.id,
          currentUser.defaultOrganizationId
        );
      } else {
        // Moving existing meal entry
        const entryId = draggableId;
        const entry = currentMealPlan.mealEntries.find(e => e.id === entryId);
        if (!entry) return;

        let newDate = '';
        let newMealType = '';

        if (destination.droppableId !== 'unplanned-recipes') {
          [newDate, newMealType] = destination.droppableId.split('_');
        }

        // Update the meal entry
        const updatedEntries = currentMealPlan.mealEntries.map(e =>
          e.id === entryId ? { ...e, date: newDate, mealType: newMealType } : e
        );

        await updateMealPlan(currentMealPlan.id, {
          mealEntries: updatedEntries,
          updatedAt: new Date()
        });
      }
    } catch (error) {
      console.error('Error handling drag and drop:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviousWeek = () => {
    setStartDate((date) => addDays(date, -7));
  };

  const handleNextWeek = () => {
    setStartDate((date) => addDays(date, 7));
  };

  const handleAddWeekToGroceryList = async () => {
    if (!currentUser?.defaultOrganizationId || !currentMealPlan) return;

    try {
      const weekRecipes = currentMealPlan.mealEntries
        .filter((entry) => {
          const entryDate = new Date(entry.date || '');
          return entryDate >= startDate && entryDate <= addDays(startDate, 6);
        })
        .map((entry) => {
          const recipe = allRecipes.find((r) => r.id === entry.recipeId);
          if (recipe) {
            return {
              recipe,
              portions: entry.portions || recipe.servings
            };
          }
          return null;
        })
        .filter(Boolean);

      for (const item of weekRecipes) {
        if (!item) continue;
        await addRecipeIngredientsManually(
          item.recipe,
          currentUser.defaultOrganizationId,
          currentMealPlan.id,
          item.portions
        );
      }
      
      // Open the grocery list sidebar after adding items
      setIsGroceryListOpen(true);
    } catch (error) {
      console.error('Error adding week to grocery list:', error);
    }
  };

  // Handle clicks outside the grocery list modal
  const handleOutsideClick = (e: React.MouseEvent) => {
    if (isGroceryListOpen) {
      const target = e.target as HTMLElement;
      const sidebar = document.getElementById('grocery-list-sidebar');
      
      if (sidebar && !sidebar.contains(target) && !target.closest('button[data-grocery-toggle]')) {
        setIsGroceryListOpen(false);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="h-screen overflow-hidden" onClick={handleOutsideClick}>
        {/* Header with Grocery List Button */}
        <div className="sticky top-0 z-20 bg-white shadow-sm py-3 px-4 mb-4 flex justify-between items-center">
          <h1 className="text-xl font-semibold text-menioo-navy">Planning des repas</h1>
          
          <div className="flex items-center space-x-4">
            <button
              data-grocery-toggle
              onClick={() => setIsGroceryListOpen(!isGroceryListOpen)}
              className="inline-flex items-center px-4 py-2 bg-menioo-green text-white rounded-lg hover:bg-menioo-green-dark transition-colors shadow-sm"
            >
              <ShoppingBasket className="h-5 w-5 mr-2" />
              Liste de courses
            </button>
          </div>
        </div>
      
        {/* Main Container */}
        <div className="flex h-[calc(100vh-64px)] overflow-hidden">
          {/* Main Calendar Content */}
          <div className="flex-1 overflow-y-auto p-4">
            {/* Week Navigation */}
            <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm mb-6">
              <button
                onClick={handlePreviousWeek}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <div className="flex items-center space-x-4">
                <h3 className="text-base font-medium text-menioo-navy">
                  Semaine du {format(startDate, 'd MMM yyyy', { locale: fr })}
                </h3>
                {!isAutomationEnabled && (
                  <button
                    onClick={handleAddWeekToGroceryList}
                    className="flex items-center space-x-2 text-menioo-green hover:text-menioo-green-dark transition-colors"
                  >
                    <ShoppingBasket className="h-5 w-5" />
                    <span className="text-sm">Ajouter la semaine</span>
                  </button>
                )}
              </div>
              <button
                onClick={handleNextWeek}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>

            {/* Calendar Grid */}
            <div className="space-y-4">
              {weekDays.map((date) => (
                <MealSectionCalendar
                  key={date.toISOString()}
                  date={date}
                  recipes={allRecipes}
                  mealEntries={currentMealPlan?.mealEntries || []}
                  onQuickAdd={handleQuickAdd}
                  isAutomationEnabled={isAutomationEnabled}
                  onOpenGroceryList={() => setIsGroceryListOpen(true)}
                  handleAddWeekToGroceryList={handleAddWeekToGroceryList}
                  weekStartDate={startDate}
                />
              ))}
            </div>
          </div>

          {/* Recipe Sidebar */}
          <div className="w-96 border-l border-gray-200 bg-white overflow-hidden flex flex-col">
            {/* Search and Filter Header */}
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-menioo-navy mb-4">Recettes</h2>
              
              {/* Search Bar */}
              <div className="relative mb-4">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                <input
                  type="search"
                  placeholder="Rechercher une recette..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-menioo-green focus:border-menioo-green"
                />
              </div>

              {/* Filter Toggle */}
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center space-x-2 text-menioo-navy hover:text-menioo-green transition-colors"
              >
                <Filter className="h-4 w-4" />
                <span className="text-sm">Filtres</span>
                {Object.values(selectedFilters).some(v => v !== false && v !== 'all') && (
                  <span className="bg-menioo-green text-white text-xs px-2 py-0.5 rounded-full">
                    Actif
                  </span>
                )}
              </button>

              {/* Filter Panel */}
              {showFilters && (
                <div className="mt-4 space-y-4">
                  {/* Dietary Restrictions */}
                  <div>
                    <h3 className="text-sm font-medium text-menioo-navy mb-2">Régime alimentaire</h3>
                    <div className="space-y-2">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedFilters.vegetarian}
                          onChange={(e) => setSelectedFilters(prev => ({ ...prev, vegetarian: e.target.checked }))}
                          className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
                        />
                        <span className="ml-2 text-sm text-gray-700">Végétarien</span>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedFilters.vegan}
                          onChange={(e) => setSelectedFilters(prev => ({ ...prev, vegan: e.target.checked }))}
                          className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
                        />
                        <span className="ml-2 text-sm text-gray-700">Végan</span>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedFilters.glutenFree}
                          onChange={(e) => setSelectedFilters(prev => ({ ...prev, glutenFree: e.target.checked }))}
                          className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
                        />
                        <span className="ml-2 text-sm text-gray-700">Sans gluten</span>
                      </label>
                    </div>
                  </div>

                  {/* Difficulty */}
                  <div>
                    <h3 className="text-sm font-medium text-menioo-navy mb-2">Difficulté</h3>
                    <select
                      value={selectedFilters.difficulty}
                      onChange={(e) => setSelectedFilters(prev => ({ ...prev, difficulty: e.target.value }))}
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
                    >
                      <option value="all">Toutes</option>
                      <option value="easy">Facile</option>
                      <option value="medium">Moyen</option>
                      <option value="hard">Difficile</option>
                    </select>
                  </div>

                  {/* Prep Time */}
                  <div>
                    <h3 className="text-sm font-medium text-menioo-navy mb-2">Temps de préparation</h3>
                    <select
                      value={selectedFilters.prepTime}
                      onChange={(e) => setSelectedFilters(prev => ({ ...prev, prepTime: e.target.value }))}
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
                    >
                      <option value="all">Tous</option>
                      <option value="quick">Rapide (&lt;30 min)</option>
                      <option value="medium">Moyen (30-60 min)</option>
                      <option value="long">Long (&gt;60 min)</option>
                    </select>
                  </div>

                  {/* Reset Filters */}
                  <button
                    onClick={() => setSelectedFilters({
                      vegetarian: false,
                      vegan: false,
                      glutenFree: false,
                      difficulty: 'all',
                      prepTime: 'all'
                    })}
                    className="text-sm text-menioo-green hover:text-menioo-green-dark transition-colors"
                  >
                    Réinitialiser les filtres
                  </button>
                </div>
              )}
            </div>

            {/* Recipe Grid */}
            <div className="flex-1 overflow-y-auto p-4">
              <Droppable droppableId="recipe-grid">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`grid grid-cols-2 gap-4 ${
                      snapshot.isDraggingOver ? 'bg-menioo-green/5' : ''
                    }`}
                  >
                    {sidebarRecipes.map((recipe, index) => (
                      <DraggableRecipe
                        key={recipe.id}
                        recipe={recipe}
                        index={index}
                        onQuickAdd={handleQuickAdd}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>

          {/* Grocery List Sidebar Modal */}
          <div 
            id="grocery-list-sidebar"
            className={`fixed top-0 right-0 h-screen w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-50 ${
              isGroceryListOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <GroceryListSidebar />
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}