import React, { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Mail, Archive, ViewIcon, ChefHat } from 'lucide-react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';
import { CategoryView } from './CategoryView';
import { RecipeView } from './RecipeView';
import { AddCustomItem } from './AddCustomItem';

type ViewMode = 'category' | 'recipe';

export function GroceryListView() {
  const [viewMode, setViewMode] = useState<ViewMode>('category');
  const [isLoading, setIsLoading] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const { currentList, archiveCurrentList, addCustomItem, generateShareToken, toggleItem } = useGroceryListStore();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);

  // Get all available recipes (both private and saved public ones)
  const allRecipes = React.useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);

  // Transform items for views
  const transformedItems = useMemo(() => {
    if (!currentList?.items) return [];

    // Create a map to store items by their unique identifier (name + unit)
    const itemsMap = new Map();

    currentList.items.forEach(item => {
      const key = `${item.name}-${item.unit}`;
      
      if (!itemsMap.has(key)) {
        // Create new aggregated item
        itemsMap.set(key, {
          ids: [item.id],
          name: item.name,
          quantity: item.quantity,
          unit: item.unit,
          category: item.category,
          checked: item.checked,
          recipeIds: [],
          mealEntryIds: []
        });

        // Only add recipe ID if it exists
        if (item.fromRecipeId) {
          itemsMap.get(key).recipeIds.push(item.fromRecipeId);
        }
        if (item.mealEntryId) {
          itemsMap.get(key).mealEntryIds.push(item.mealEntryId);
        }
      } else {
        // Update existing aggregated item
        const existingItem = itemsMap.get(key);
        existingItem.ids.push(item.id);
        
        // Handle recipe IDs
        if (item.fromRecipeId) {
          if (!existingItem.recipeIds.includes(item.fromRecipeId)) {
            existingItem.recipeIds.push(item.fromRecipeId);
          }
        }

        // Handle meal entry IDs
        if (item.mealEntryId) {
          if (!existingItem.mealEntryIds.includes(item.mealEntryId)) {
            existingItem.mealEntryIds.push(item.mealEntryId);
          }
        }

        // Create a new item if mixing recipe and non-recipe items
        if (
          (item.fromRecipeId && existingItem.recipeIds.length === 0) ||
          (!item.fromRecipeId && existingItem.recipeIds.length > 0)
        ) {
          // Create a new key for this item
          const newKey = `${key}-${crypto.randomUUID()}`;
          itemsMap.set(newKey, {
            ids: [item.id],
            name: item.name,
            quantity: item.quantity,
            unit: item.unit,
            category: item.category,
            checked: item.checked,
            recipeIds: item.fromRecipeId ? [item.fromRecipeId] : [],
            mealEntryIds: item.mealEntryId ? [item.mealEntryId] : []
          });
        } else {
          // Only aggregate quantities for items with matching recipe status
          existingItem.quantity += item.quantity;
          existingItem.checked = existingItem.checked && item.checked;
        }
      }
    });

    return Array.from(itemsMap.values());
  }, [currentList?.items]);

  const handleSendEmail = async () => {
    if (!currentList) {
      alert("Aucune liste de courses active");
      return;
    }

    const recipient = prompt("Entrez l'adresse email du destinataire :");
    if (!recipient) {
      alert("Email non envoyé : aucun destinataire fourni");
      return;
    }

    setIsLoading(true);
    try {
      const shareToken = await generateShareToken(currentList.id);
      const shareableUrl = `${window.location.origin}/printable-grocery-list/${shareToken}`;

      const emailHTML = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Votre liste de courses Menioo</title>
</head>
<body style="margin: 0; padding: 0; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #333333; background-color: #f8f8f5;">
  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; margin: 0 auto; background-color: #ffffff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);">
    <!-- Header with logo -->
    <tr>
      <td align="center" bgcolor="#4CAF50" style="padding: 20px 0;">
        <table border="0" cellpadding="0" cellspacing="0" width="80%">
          <tr>
            <td align="center">
              <h1 style="font-family: Helvetica, Arial, sans-serif; font-weight: 700; font-size: 28px; color: #ffffff; margin: 0;">Menioo</h1>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- Content -->
    <tr>
      <td style="padding: 30px;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
            <td>
              <h2 style="margin-top: 0; font-family: Helvetica, Arial, sans-serif; color: #1B2B34; font-size: 22px;">Votre liste de courses est prête !</h2>
              <p style="color: #555555; line-height: 1.6;">Bonjour !</p>
              <p style="color: #555555; line-height: 1.6;">Une liste de courses a été partagée avec vous. Accédez-y facilement en cliquant sur le bouton ci-dessous :</p>
            </td>
          </tr>
          <tr>
            <td align="center" style="padding: 20px 0;">
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td align="center" bgcolor="#FF9800" style="border-radius: 6px; padding: 0;">
                    <a href="${shareableUrl}" target="_blank" style="display: inline-block; padding: 14px 28px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; color: #ffffff; text-decoration: none; font-weight: bold;">Voir ma liste de courses</a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <p style="color: #555555;">Vous pouvez également copier ce lien dans votre navigateur :</p>
              <div style="background-color: #f5f5f0; border-radius: 6px; padding: 15px; word-break: break-all; color: #666666;">
                ${shareableUrl}
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- Footer -->
    <tr>
      <td bgcolor="#f5f5f0" style="padding: 20px 30px; border-top: 1px solid #eeeeee;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
            <td style="color: #777777; font-size: 13px; line-height: 1.5;">
              <p style="margin: 0 0 10px 0;">Ce lien expirera dans 30 jours.</p>
              <p style="margin: 0;">Envoyé depuis Menioo - Votre assistant de planification de repas</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
      `;

      const plainText = `
        Bonjour !
        
        Une liste de courses a été partagée avec vous.
        Pour la consulter, cliquez sur ce lien : ${shareableUrl}
        
        Ce lien expirera dans 30 jours.
        
        Envoyé depuis Menioo - Votre assistant de planification de repas
      `;

      const response = await fetch('https://menioo-backend-production.up.railway.app/api/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          to: recipient,
          subject: "Votre liste de courses Menioo",
          html: emailHTML,
          text: plainText,
          from: {
            email: "noreply@menioo.com",
            name: "Menioo"
          }
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Échec de l\'envoi de l\'email');
      }

      alert("La liste de courses a été envoyée avec succès !");
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email:", error);
      alert('Impossible d\'envoyer la liste de courses. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleItems = async (ids: string[]) => {
    if (!ids.length) return;
    
    try {
      for (const id of ids) {
        await toggleItem(id);
      }
    } catch (error) {
      console.error('Error toggling items:', error);
    }
  };

  return (
    <div className="h-[calc(100vh-64px)] flex flex-col bg-white">
      {/* Header */}
      <div className="sticky top-0 z-10 flex-shrink-0 bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-5">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div className="flex items-center space-x-4">
              <h2 className="text-xl font-display font-bold text-menioo-navy">
                Liste de courses
              </h2>

              {/* View Toggle */}
              <div className="flex items-center bg-gray-100 rounded-lg p-1">
                <button
                  onClick={() => setViewMode('category')}
                  className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                    viewMode === 'category'
                      ? 'bg-white text-menioo-navy shadow-sm'
                      : 'text-gray-600 hover:text-menioo-navy'
                  }`}
                >
                  <ViewIcon className="h-4 w-4 inline-block mr-1.5" />
                  Catégories
                </button>
                <button
                  onClick={() => setViewMode('recipe')}
                  className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                    viewMode === 'recipe'
                      ? 'bg-white text-menioo-navy shadow-sm'
                      : 'text-gray-600 hover:text-menioo-navy'
                  }`}
                >
                  <ChefHat className="h-4 w-4 inline-block mr-1.5" />
                  Recettes
                </button>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <button
                onClick={handleSendEmail}
                disabled={isLoading || !currentList}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-menioo-green bg-menioo-green/10 rounded-lg hover:bg-menioo-green/20 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Mail className="h-4 w-4 mr-2" />
                Envoyer
              </button>
              <button
                onClick={() => archiveCurrentList()}
                disabled={isLoading || !currentList}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Archive className="h-4 w-4 mr-2" />
                Archiver
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Custom Item Section */}
      <div className="sticky top-[104px] z-10 flex-shrink-0 p-4 border-b border-gray-200 bg-white">
        <AddCustomItem onAdd={addCustomItem} />
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto">
        {!currentList ? (
          <div className="p-4 text-center text-gray-500">
            No active grocery list
          </div>
        ) : viewMode === 'category' ? (
          <CategoryView 
            items={transformedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItems}
          />
        ) : (
          <RecipeView 
            items={transformedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItems}
          />
        )}
      </div>
    </div>
  );
}