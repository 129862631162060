import React from 'react';
import { Recipe } from '../../types';
import { Check, Trash2, MinusCircle, PlusCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { removeIngredientsByRecipe, updateRecipePortions } from '../../utils/mealPlanUtils';
import { useAuthStore } from '../../store/useAuthStore';

interface RecipeViewProps {
  items: Array<{
    ids: string[];
    name: string;
    quantity: number;
    unit: string;
    category: string;
    checked: boolean;
    recipeIds?: string[];
  }>;
  recipes: Recipe[];
  onToggleItem: (ids: string[]) => void;
}

export function RecipeView({ items = [], recipes = [], onToggleItem }: RecipeViewProps) {
  const { t } = useTranslation();
  const currentUser = useAuthStore((state) => state.currentUser);

  // Group items by recipe
  const groupedByRecipe = React.useMemo(() => {
    const grouped: Record<string, typeof items> = {
      noRecipe: []
    };

    items.forEach(item => {
      if (!item.recipeIds || item.recipeIds.length === 0) {
        // Items without recipe go to noRecipe group
        grouped.noRecipe.push(item);
      } else {
        // Add item to each recipe group it belongs to, but only count quantity once
        const recipeCount = item.recipeIds.length;
        item.recipeIds.forEach(recipeId => {
          if (!recipeId) return;
          if (!grouped[recipeId]) {
            grouped[recipeId] = [];
          }
          // Divide quantity by number of recipes using this ingredient
          grouped[recipeId].push({
            ...item,
            quantity: item.quantity / recipeCount
          });
        });
      }
    });

    return grouped;
  }, [items]);

  // Format quantity and unit with proper translation
  const formatQuantityAndUnit = (quantity: number, unit: string) => {
    if (!unit) return `${quantity}`;
    const translatedUnit = t(`units.${unit}`, { defaultValue: unit });
    return `${quantity} ${translatedUnit.toLowerCase()}`;
  };

  const handleRemoveRecipe = async (recipeId: string) => {
    if (!currentUser?.defaultOrganizationId) return;
    try {
      await removeIngredientsByRecipe(recipeId, currentUser.defaultOrganizationId);
    } catch (error) {
      console.error('Error removing recipe:', error);
    }
  };

  const handleUpdatePortions = async (recipeId: string, change: number) => {
    if (!currentUser?.defaultOrganizationId) return;
    const recipe = recipes.find(r => r.id === recipeId);
    if (!recipe) return;

    try {
      await updateRecipePortions(recipeId, currentUser.defaultOrganizationId, change);
    } catch (error) {
      console.error('Error updating portions:', error);
    }
  };

  return (
    <div className="divide-y divide-gray-100">
      {/* Items without recipes */}
      {groupedByRecipe.noRecipe.length > 0 && (
        <div className="p-6">
          <h3 className="text-lg font-medium text-menioo-navy mb-4">
            Sans recette
          </h3>
          <ul className="space-y-3">
            {groupedByRecipe.noRecipe.map((item) => (
              <li key={item.ids[0]} className="flex items-start">
                <button
                  onClick={() => onToggleItem(item.ids)}
                  className={`flex-shrink-0 w-5 h-5 mt-1 rounded border-2 mr-3 flex items-center justify-center transition-colors ${
                    item.checked
                      ? 'bg-menioo-green border-menioo-green'
                      : 'border-gray-300 hover:border-menioo-green'
                  }`}
                >
                  {item.checked && <Check className="h-3 w-3 text-white" />}
                </button>
                <div className={`flex-1 ${item.checked ? 'line-through text-gray-400' : ''}`}>
                  <span className="font-medium">
                    {formatQuantityAndUnit(item.quantity, item.unit)}
                  </span>{' '}
                  {item.name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Recipe-grouped items */}
      {Object.entries(groupedByRecipe)
        .filter(([key]) => key !== 'noRecipe')
        .map(([recipeId, items]) => {
          const recipe = recipes.find(r => r.id === recipeId);
          if (!recipe) return null;

          return (
            <div key={recipeId} className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-menioo-navy">
                  {recipe.name}
                </h3>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleUpdatePortions(recipeId, -1)}
                      className="p-1 text-gray-500 hover:text-menioo-green transition-colors"
                    >
                      <MinusCircle className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleUpdatePortions(recipeId, 1)}
                      className="p-1 text-gray-500 hover:text-menioo-green transition-colors"
                    >
                      <PlusCircle className="h-5 w-5" />
                    </button>
                  </div>
                  <button
                    onClick={() => handleRemoveRecipe(recipeId)}
                    className="p-1 text-red-500 hover:text-red-600 transition-colors"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <ul className="space-y-3">
                {items.map((item) => (
                  <li key={item.ids[0]} className="flex items-start">
                    <button
                      onClick={() => onToggleItem(item.ids)}
                      className={`flex-shrink-0 w-5 h-5 mt-1 rounded border-2 mr-3 flex items-center justify-center transition-colors ${
                        item.checked
                          ? 'bg-menioo-green border-menioo-green'
                          : 'border-gray-300 hover:border-menioo-green'
                      }`}
                    >
                      {item.checked && <Check className="h-3 w-3 text-white" />}
                    </button>
                    <div className={`flex-1 ${item.checked ? 'line-through text-gray-400' : ''}`}>
                      <span className="font-medium">
                        {formatQuantityAndUnit(item.quantity, item.unit)}
                      </span>{' '}
                      {item.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
    </div>
  );
}