import React, { useState, useEffect, useRef } from 'react';
import { Search, Plus } from 'lucide-react';
import { useIngredientSearchStore } from '../../store/useIngredientSearchStore';
import { StandardIngredient } from '../../types/ingredients';
import { useTranslation } from 'react-i18next';

interface GroceryItemSelectorProps {
  value: {
    name: string;
    unit: string;
  };
  onChange: (ingredient: StandardIngredient | { name: string; category: string; preferredUnit: string }) => void;
  onCustomInputChange?: (value: string) => void;
  className?: string;
}

export function GroceryItemSelector({ value, onChange, onCustomInputChange, className = '' }: GroceryItemSelectorProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [customName, setCustomName] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    searchQuery,
    setSearchQuery,
    getFilteredIngredients,
    initializeIngredients,
    reset
  } = useIngredientSearchStore();

  const filteredIngredients = getFilteredIngredients();

  useEffect(() => {
    const unsubscribe = initializeIngredients();
    return unsubscribe;
  }, [initializeIngredients]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isOpen) {
      if (e.key === 'Enter' || e.key === 'ArrowDown') {
        setIsOpen(true);
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < filteredIngredients.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > -1 ? prev - 1 : -1);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0 && selectedIndex < filteredIngredients.length) {
          handleSelect(filteredIngredients[selectedIndex]);
        } else if (customName && filteredIngredients.length === 0) {
          handleAddCustom(customName);
        } else if (customName) {
          // Allow adding custom item even if there are filtered ingredients
          handleAddCustom(customName);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        setSelectedIndex(-1);
        break;
    }
  };

  const handleSelect = (ingredient: StandardIngredient) => {
    onChange(ingredient);
    setIsOpen(false);
    setSelectedIndex(-1);
    // Instead of clearing the search query, set it to the selected ingredient name
    setSearchQuery(ingredient.name);
    setCustomName('');
  };

  const handleAddCustom = (name: string) => {
    onChange({
      name,
      category: 'other',
      preferredUnit: 'piece'
    });
    setIsOpen(false);
    setSelectedIndex(-1);
    // Clear the search query after adding custom item
    setSearchQuery(name);
    setCustomName('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    setCustomName(value);
    if (onCustomInputChange) {
      onCustomInputChange(value);
    }
    setSelectedIndex(-1);
    if (!isOpen && value) {
      setIsOpen(true);
    }
  };

  return (
    <div ref={containerRef} className={`relative ${className}`}>
      <div className="relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
        <input
          ref={inputRef}
          type="text"
          value={searchQuery}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onClick={() => setIsOpen(true)}
          className="w-full h-10 pl-10 pr-4 border border-gray-300 rounded-md shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
          placeholder={t('Chercher un article')}
        />
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200">
          <ul className="max-h-60 overflow-y-auto py-1">
            {filteredIngredients.map((ingredient, index) => (
              <li
                key={ingredient.id}
                onClick={() => handleSelect(ingredient)}
                onMouseEnter={() => setSelectedIndex(index)}
                className={`px-4 py-2 cursor-pointer ${
                  index === selectedIndex ? 'bg-menioo-green/10' : 'hover:bg-gray-50'
                }`}
              >
                <div className="font-medium">{ingredient.name}</div>
                {ingredient.aliases?.length > 0 && (
                  <div className="text-sm text-gray-500">
                    {ingredient.aliases.join(', ')}
                  </div>
                )}
              </li>
            ))}
            {/* Always show the custom item option if there's input */}
            {customName && (
              <li
                onClick={() => handleAddCustom(customName)}
                className={`px-4 py-2 cursor-pointer flex items-center text-menioo-green hover:bg-gray-50`}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add "{customName}"
              </li>
            )}
            {!customName && filteredIngredients.length === 0 && (
              <li className="px-4 py-2 text-gray-500 italic">
                No ingredients found. Type a name to add a custom item.
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}