import React, { useState } from 'react';
import { Mail, Archive, ViewIcon, ChefHat, Plus } from 'lucide-react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';

import { CategoryView } from '../grocery/CategoryView';
import { RecipeView } from '../grocery/RecipeView';
import { GroceryItemSelector } from '../../components/grocery/GroceryItemSelector';

import { useTranslation } from 'react-i18next';
import { StandardIngredient } from '../../types/ingredients';
import { standardUnits } from '../../types/units';
import { useAuthStore } from '../../store/useAuthStore';
import { addDoc, collection, doc, updateDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';

type ViewMode = 'category' | 'recipe';

export function GroceryListSidebar() {
  const [viewMode, setViewMode] = useState<ViewMode>('category');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState<StandardIngredient | null>(null);
  const [newItemQuantity, setNewItemQuantity] = useState('1');
  const [newItemUnit, setNewItemUnit] = useState('piece');
  const { t } = useTranslation();
  const currentUser = useAuthStore((state) => state.currentUser);
  const { currentList, archiveCurrentList, generateShareToken, toggleItem } = useGroceryListStore();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);

  // Get all available recipes
  const allRecipes = React.useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);

  const createNewGroceryList = async () => {
    if (!currentUser?.defaultOrganizationId) return null;

    const startDate = startOfWeek(new Date(), { locale: fr });
    const endDate = endOfWeek(startDate, { locale: fr });

    const newList = {
      name: `Liste de courses du ${format(startDate, 'd MMMM', { locale: fr })}`,
      organizationId: currentUser.defaultOrganizationId,
      status: 'active',
      items: [],
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };

    const docRef = await addDoc(collection(db, 'groceryLists'), newList);
    return docRef.id;
  };

  const handleIngredientSelect = (ingredient: StandardIngredient) => {
    setSelectedIngredient(ingredient);
    setNewItemUnit(ingredient.preferredUnit || 'piece');
  };

  const handleAddCustomItem = async () => {
    if (!currentUser?.defaultOrganizationId || !selectedIngredient) return;

    try {
      // Check if we need to create a new grocery list
      let listId = currentList?.id;
      
      if (!listId) {
        listId = await createNewGroceryList();
      }

      if (!listId) return;

      const newItem = {
        id: crypto.randomUUID(),
        name: selectedIngredient.name,
        quantity: Number(newItemQuantity),
        unit: newItemUnit || selectedIngredient.preferredUnit || 'piece',
        category: selectedIngredient.category || 'other',
        standardIngredientId: selectedIngredient.id || '',
        checked: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const listRef = doc(db, 'groceryLists', listId);
      await updateDoc(listRef, {
        items: currentList?.items ? [...currentList.items, newItem] : [newItem],
        updatedAt: serverTimestamp()
      });

      // Reset form
      setSelectedIngredient(null);
      //setNewItemQuantity('');
      setNewItemUnit('piece');
      setIsAddingItem(false);
    } catch (error) {
      console.error('Error adding custom item:', error);
    }
  };

  // Transform items for views
  const transformedItems = React.useMemo(() => {
    if (!currentList?.items) return [];

    const itemsMap = new Map();

    currentList.items.forEach(item => {
      const key = `${item.name}-${item.unit}`;
      
      if (!itemsMap.has(key)) {
        itemsMap.set(key, {
          ids: [item.id],
          name: item.name,
          quantity: item.quantity,
          unit: item.unit,
          category: item.category,
          checked: item.checked,
          recipeIds: [],
          mealEntryIds: []
        });

        if (item.fromRecipeId) {
          itemsMap.get(key).recipeIds.push(item.fromRecipeId);
        }
        if (item.mealEntryId) {
          itemsMap.get(key).mealEntryIds.push(item.mealEntryId);
        }
      } else {
        const existingItem = itemsMap.get(key);
        existingItem.ids.push(item.id);
        existingItem.quantity += item.quantity;
        existingItem.checked = existingItem.checked && item.checked;
        
        if (item.fromRecipeId && !existingItem.recipeIds.includes(item.fromRecipeId)) {
          existingItem.recipeIds.push(item.fromRecipeId);
        }
        if (item.mealEntryId && !existingItem.mealEntryIds.includes(item.mealEntryId)) {
          existingItem.mealEntryIds.push(item.mealEntryId);
        }
      }
    });

    return Array.from(itemsMap.values());
  }, [currentList?.items]);

  const handleSendEmail = async () => {
    if (!currentList) {
      alert("Aucune liste de courses active");
      return;
    }

    const recipient = prompt("Entrez l'adresse email du destinataire :");
    if (!recipient) {
      alert("Email non envoyé : aucun destinataire fourni");
      return;
    }

    setIsLoading(true);
    try {
      const shareToken = await generateShareToken(currentList.id);
      const shareableUrl = `${window.location.origin}/printable-grocery-list/${shareToken}`;

      const emailHTML = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Votre liste de courses Menioo</title>
</head>
<body style="margin: 0; padding: 0; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #333333; background-color: #f8f8f5;">
  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; margin: 0 auto; background-color: #ffffff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);">
    <!-- Header with logo -->
    <tr>
      <td align="center" bgcolor="#4CAF50" style="padding: 20px 0;">
        <table border="0" cellpadding="0" cellspacing="0" width="80%">
          <tr>
            <td align="center">
              <h1 style="font-family: Helvetica, Arial, sans-serif; font-weight: 700; font-size: 28px; color: #ffffff; margin: 0;">Menioo</h1>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- Content -->
    <tr>
      <td style="padding: 30px;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
            <td>
              <h2 style="margin-top: 0; font-family: Helvetica, Arial, sans-serif; color: #1B2B34; font-size: 22px;">Votre liste de courses est prête !</h2>
              <p style="color: #555555; line-height: 1.6;">Bonjour !</p>
              <p style="color: #555555; line-height: 1.6;">Une liste de courses a été partagée avec vous. Accédez-y facilement en cliquant sur le bouton ci-dessous :</p>
            </td>
          </tr>
          <tr>
            <td align="center" style="padding: 20px 0;">
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td align="center" bgcolor="#FF9800" style="border-radius: 6px; padding: 0;">
                    <a href="${shareableUrl}" target="_blank" style="display: inline-block; padding: 14px 28px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; color: #ffffff; text-decoration: none; font-weight: bold;">Voir ma liste de courses</a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <p style="color: #555555;">Vous pouvez également copier ce lien dans votre navigateur :</p>
              <div style="background-color: #f5f5f0; border-radius: 6px; padding: 15px; word-break: break-all; color: #666666;">
                ${shareableUrl}
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- Footer -->
    <tr>
      <td bgcolor="#f5f5f0" style="padding: 20px 30px; border-top: 1px solid #eeeeee;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
            <td style="color: #777777; font-size: 13px; line-height: 1.5;">
              <p style="margin: 0 0 10px 0;">Ce lien expirera dans 30 jours.</p>
              <p style="margin: 0;">Envoyé depuis Menioo - Votre assistant de planification de repas</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
      `;

      const plainText = `
        Bonjour !
        
        Une liste de courses a été partagée avec vous.
        Pour la consulter, cliquez sur ce lien : ${shareableUrl}
        
        Ce lien expirera dans 30 jours.
        
        Envoyé depuis Menioo - Votre assistant de planification de repas
      `;

      const response = await fetch('https://menioo-backend-production.up.railway.app/api/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          to: recipient,
          subject: "Votre liste de courses Menioo",
          html: emailHTML,
          text: plainText,
          from: {
            email: "noreply@menioo.com",
            name: "Menioo"
          }
        }),
      });

      if (!response.ok) {
        throw new Error("Échec de l'envoi de l'email");
      }

      alert("La liste de courses a été envoyée avec succès !");
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email:", error);
      alert('Impossible d\'envoyer la liste de courses. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleItems = async (ids: string[]) => {
    if (!ids.length) return;
    
    try {
      for (const id of ids) {
        await toggleItem(id);
      }
    } catch (error) {
      console.error('Error toggling items:', error);
    }
  };

  return (
    <div className="h-screen overflow-hidden flex flex-col">
      {/* Header */}
      <div className="px-4 py-3 border-b border-gray-200 flex items-center justify-between">
        <div className="flex items-center">
          <ChefHat className="h-5 w-5 text-menioo-green mr-2" />
          <h2 className="font-medium text-lg text-menioo-navy">Liste de courses</h2>
        </div>
        <button
          onClick={() => setIsAddingItem(!isAddingItem)}
          className="p-1 text-menioo-green hover:text-menioo-green-dark rounded-full hover:bg-green-50 transition-colors"
        >
          <Plus className="h-5 w-5" />
        </button>
      </div>
      
      {/* Add Item Form */}
      {isAddingItem && (
        <div className="p-3 border-b border-gray-200">
          <h3 className="text-sm font-medium text-menioo-navy mb-2">Ajouter un article</h3>
          <div className="space-y-3">
            <div>
              <label className="block text-xs text-gray-500 mb-1">Article</label>
              <div className="w-full">
                <GroceryItemSelector
                  value={{ name: selectedIngredient?.name || '', unit: newItemUnit }}
                  onChange={handleIngredientSelect}
                  className="w-full"
                />
              </div>
            </div>
            
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label className="block text-xs text-gray-500 mb-1">Quantité</label>
                <input
                  type="number"
                  onChange={(e) => setNewItemQuantity(e.target.value)}
                  className="w-full h-9 px-3 rounded-md border border-gray-300 shadow-sm focus:border-menioo-green focus:ring-1 focus:ring-menioo-green"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500 mb-1">Unité</label>
                <select
                  value={newItemUnit}
                  onChange={(e) => setNewItemUnit(e.target.value)}
                  className="w-full h-9 px-3 rounded-md border border-gray-300 shadow-sm focus:border-menioo-green focus:ring-1 focus:ring-menioo-green"
                >
                  {Object.entries(standardUnits).map(([unit, label]) => (
                    <option key={unit} value={unit}>{label.fr}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsAddingItem(false)}
                className="px-3 py-1 text-xs text-gray-600 hover:bg-gray-100 rounded"
              >
                Annuler
              </button>
              <button
                onClick={handleAddCustomItem}
                disabled={!selectedIngredient}
                className="px-3 py-1 text-xs bg-menioo-green text-white rounded disabled:opacity-50"
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Tabs */}
      <div className="flex border-b border-gray-200">
        <button
          onClick={() => setViewMode('category')}
          className={`flex-1 py-2 text-sm font-medium ${
            viewMode === 'category'
              ? 'text-menioo-green border-b-2 border-menioo-green'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <ViewIcon className="h-4 w-4 inline-block mr-1" />
          Catégories
        </button>
        <button
          onClick={() => setViewMode('recipe')}
          className={`flex-1 py-2 text-sm font-medium ${
            viewMode === 'recipe'
              ? 'text-menioo-green border-b-2 border-menioo-green'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <ChefHat className="h-4 w-4 inline-block mr-1" />
          Recettes
        </button>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto">
        {!currentList ? (
          <div className="flex flex-col items-center justify-center h-full p-4 text-gray-500">
            <ChefHat className="h-12 w-12 mb-3 text-gray-300" />
            <p>Aucune liste de courses active</p>
          </div>
        ) : viewMode === 'category' ? (
          <CategoryView 
            items={transformedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItems}
          />
        ) : (
          <RecipeView 
            items={transformedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItems}
          />
        )}
      </div>

      {/* Footer Actions */}
      <div className="p-3 border-t border-gray-200">
        <div className="space-y-2">
          <button
            onClick={handleSendEmail}
            disabled={isLoading || !currentList}
            className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Mail className="h-4 w-4 mr-2" />
            Envoyer par mail 
          </button>
          <button
            onClick={() => archiveCurrentList()}
            disabled={isLoading || !currentList}
            className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Archive className="h-4 w-4 mr-2" />
            Fermer la liste
          </button>
        </div>
      </div>
    </div>
  );
}