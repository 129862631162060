import React from 'react';
import { MainNavigation } from '../main/MainNavigation';
import { Link } from 'react-router-dom';

export function LandingPage() {
  return (
    <div className="min-h-screen bg-menioo-cream">
      {/* Main Navigation */}
      <MainNavigation />

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4">
        <section className="text-center py-8">
          <h1 className="text-4xl font-bold text-menioo-navy">
            Simplifiez vos repas avec <span className="text-menioo-orange">Menioo</span>
          </h1>
          <p className="mt-4 text-lg text-gray-700">
            Découvrez des recettes rapides et simples, planifiez vos repas et gérez vos listes de courses 
            en quelques clics. Menioo vous aide à gagner du temps et à simplifier votre quotidien.
          </p>
          <div className="mt-6 flex flex-col sm:flex-row items-center justify-center gap-4">
            <button
              className="w-full sm:w-auto bg-menioo-orange text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-orange-700 transition"
              onClick={() => (window.location.href = '/recipes')}
            >
              Explorer les recettes
            </button>
            <Link 
              to="/why-menioo" 
              className="w-full sm:w-auto whitespace-nowrap bg-white border border-menioo-orange text-menioo-orange px-6 py-3 rounded-full font-semibold text-lg hover:bg-orange-100 transition"
            >
              En savoir plus
            </Link>
          </div>
        </section>

        {/* Features Section */}
        <section className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-menioo-navy">Recettes pour tous les jours</h2>
            <p className="mt-2 text-gray-600">
              Trouvez des recettes rapides et faciles adaptées à vos besoins quotidiens. 
              Ajoutez-les directement à vos listes de courses.
            </p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-menioo-navy">Planification simplifiée</h2>
            <p className="mt-2 text-gray-600">
              Planifiez vos repas de la semaine grâce à une interface intuitive et flexible. 
              Organisez vos recettes en fonction de votre emploi du temps.
            </p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-menioo-navy">Liste de courses intelligente</h2>
            <p className="mt-2 text-gray-600">
              Générez automatiquement des listes de courses à partir de vos repas planifiés. 
              Ajoutez des articles personnalisés en quelques secondes.
            </p>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="mt-16 text-center">
          <h2 className="text-3xl font-bold text-menioo-navy">Ils adorent Menioo</h2>
          <p className="mt-4 text-lg text-gray-700">
            Des centaines d'utilisateurs utilisent Menioo pour simplifier leur vie quotidienne. 
            Rejoignez-les dès aujourd'hui !
          </p>
        </section>

        <section className="fade-in mb-12" style={{animationDelay: '0.3s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Comment fonctionne Menioo ?</h2>
          <ol className="space-y-4 list-decimal pl-5">
            <li className="pl-2">
              <strong className="text-menioo-navy">Ajoutez vos propres recettes</strong> ou explorez celles de la communauté.
            </li>
            <li className="pl-2">
              <strong className="text-menioo-navy">Générez votre liste de courses</strong> en un clic.
            </li>
            <li className="pl-2">
              <strong className="text-menioo-navy">Faites vos courses et cuisinez !</strong> Utilisez votre liste en magasin ou synchronisez-la avec un service de livraison.
            </li>
          </ol>
        </section>

        <section className="fade-in mb-12" style={{animationDelay: '0.4s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Menioo : Une communauté engagée</h2>
          <p className="text-gray-700">
            Menioo, ce n'est pas seulement une application, c'est aussi une communauté d'utilisateurs qui partagent leurs meilleures recettes, astuces et idées pour une alimentation variée et équilibrée. Chaque utilisateur peut contribuer et enrichir la plateforme en partageant ses propres créations culinaires.
          </p>
        </section>

        <section className="fade-in mb-12" style={{animationDelay: '0.5s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Menioo est fait pour vous si...</h2>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-2xl mr-3">👨‍👩‍👧‍👦</span>
              <span>Vous êtes une famille qui veut mieux organiser ses repas.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">👩‍💻</span>
              <span>Vous êtes un professionnel débordé cherchant à gagner du temps.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🌱</span>
              <span>Vous suivez un régime spécifique et cherchez des suggestions adaptées.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🍽️</span>
              <span>Vous aimez partager vos recettes et découvrir celles des autres.</span>
            </li>
            <li className="flex items-start">
              <span className="text-2xl mr-3">🌍</span>
              <span>Vous voulez réduire le gaspillage alimentaire.</span>
            </li>
          </ul>
        </section>

        <section className="fade-in text-center mb-12" style={{animationDelay: '0.6s'}}>
          <h2 className="text-3xl font-display font-bold text-menioo-navy mb-4">Essayez Menioo dès aujourd'hui !</h2>
          <p className="text-gray-700 mb-6">Menioo est disponible gratuitement et vous aide à simplifier votre quotidien dès maintenant.</p>
          <p>
            <Link to="/signup" className="cta-button">
              Inscrivez-vous gratuitement
            </Link>
          </p>
        </section>

        <footer className="fade-in border-t border-gray-200 pt-6 mt-8" style={{animationDelay: '0.7s'}}>
          <p className="text-center text-gray-500">&copy; 2025 Menioo. Tous droits réservés.</p>
        </footer>
      </main>

      {/* Footer */}
      <footer className="bg-menioo-navy text-white py-6 mt-8">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
          <div className="text-sm">
            © {new Date().getFullYear()} Menioo. Tous droits réservés.
          </div>
        </div>
      </footer>
    </div>
  );
}