import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  Clock, 
  Users, 
  ArrowLeft, 
  Pencil, 
  Trash2, 
  Link as LinkIcon,
  ChefHat,
  Utensils,
  Timer,
  Share2
} from 'lucide-react';
import { useStore } from '../../store/useStore';
import { useAuthStore } from '../../store/useAuthStore';
import { useTranslation } from 'react-i18next';

// Default image URL for recipes without images
const DEFAULT_RECIPE_IMAGE = "https://lh3.googleusercontent.com/d/1ej0k5VtVASrLCTiLR-6tqXHU4400jk9T";

export function RecipeDetailNew() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const removeRecipe = useStore((state) => state.removeRecipe);
  const currentUser = useAuthStore((state) => state.currentUser);
  const currentOrganization = useAuthStore((state) => state.currentOrganization);

  // Find recipe in either public or private recipes
  const recipe = [...recipes, ...publicRecipes].find((r) => r.id === id);

  const canEditRecipe = () => {
    if (!currentUser || !currentOrganization || !recipe) return false;
    
    // Allow editing if user is owner or contributor of the organization
    const userRole = currentUser.organizations[currentOrganization.id]?.role;
    if (userRole === 'owner' || userRole === 'contributor') {
      return recipe.organizationId === currentOrganization.id;
    }
    
    return false;
  };

  if (!recipe) {
    return (
      <div className="min-h-[60vh] flex flex-col items-center justify-center">
        <ChefHat className="h-16 w-16 text-menioo-green mb-4" />
        <h2 className="text-xl font-display font-bold text-menioo-navy mb-2">Recipe not found</h2>
        <Link 
          to="/recipes" 
          className="text-menioo-green hover:text-menioo-green-dark transition-colors"
        >
          Back to recipes
        </Link>
      </div>
    );
  }

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this recipe?')) {
      try {
        await removeRecipe(recipe.id);
        navigate('/recipes');
      } catch (error) {
        console.error('Error deleting recipe:', error);
      }
    }
  };

  const recipeImage = recipe.image || DEFAULT_RECIPE_IMAGE;

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-xl overflow-hidden">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={recipeImage}
          alt={recipe.name}
          className="w-full h-48 sm:h-80 object-cover"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null; // Prevent infinite loop
            target.src = DEFAULT_RECIPE_IMAGE;
          }}
        />
        <div className="absolute top-4 left-4 flex space-x-2 z-10">
           <button
            onClick={() => navigate(-1)}
            className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
          >
            <ArrowLeft className="h-5 w-5 text-menioo-navy" />
          </button>
        </div>
        {canEditRecipe() && (
          <div className="absolute top-4 right-4 flex space-x-2 z-10">
            <Link
              to={`/recipes/${recipe.id}/edit`}
              className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
            >
              <Pencil className="h-5 w-5 text-menioo-navy" />
            </Link>
            <button
              onClick={handleDelete}
              className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-red-50 transition-colors"
            >
              <Trash2 className="h-5 w-5 text-red-500" />
            </button>
          </div>
        )}
      </div>

      <div className="p-4 sm:p-8">
        {/* Recipe Header */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-display font-bold text-menioo-navy">
              {recipe.name}
            </h1>
            <div className="mt-2 flex flex-wrap gap-4 text-sm text-gray-600">
              <div className="flex items-center">
                <Timer className="h-4 w-4 mr-1 text-menioo-orange" />
                <span>Prép: {recipe.prepTime} mins</span>
              </div>
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-1 text-menioo-orange" />
                <span>Cuisson: {recipe.cookTime} mins</span>
              </div>
              <div className="flex items-center">
                <Users className="h-4 w-4 mr-1 text-menioo-orange" />
                <span>{recipe.servings} portions</span>
              </div>
            </div>
          </div>
          
          <div className="flex flex-wrap gap-2">
            {recipe.sourceUrl && (
              <a
                href={recipe.sourceUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-menioo-green hover:text-menioo-green-dark transition-colors"
              >
                <LinkIcon className="h-5 w-5 mr-1" />
                <span>Source</span>
              </a>
            )}
            <button
              onClick={() => navigator.clipboard.writeText(window.location.href)}
              className="flex items-center text-menioo-green hover:text-menioo-green-dark transition-colors"
            >
              <Share2 className="h-5 w-5 mr-1" />
              <span>Partager</span>
            </button>
          </div>
        </div>

        {/* Ingredients Section */}
        <div className="mt-8">
          <h2 className="text-xl font-display font-semibold text-menioo-navy flex items-center">
            <Utensils className="h-5 w-5 mr-2 text-menioo-green" />
            Ingrédients
          </h2>
          <ul className="mt-4 space-y-2">
            {recipe.ingredients.map((ingredient) => (
              <li 
                key={ingredient.id} 
                className="flex items-center p-3 rounded-lg bg-menioo-cream/50 hover:bg-menioo-cream transition-colors"
              >
                <span className="w-20 sm:w-24 text-sm font-medium text-menioo-navy">
                  {ingredient.quantity} {t(`units.${ingredient.unit}`)}
                </span>
                <span className="text-gray-700">{ingredient.name}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Instructions Section */}
        <div className="mt-8">
          <h2 className="text-xl font-display font-semibold text-menioo-navy flex items-center">
            <ChefHat className="h-5 w-5 mr-2 text-menioo-green" />
            Etapes
          </h2>
          <div className="mt-4 prose prose-menioo max-w-none">
            {recipe.instructions.split('\n').map((paragraph, index) => (
              <p key={index} className="text-gray-700 mb-4">
                {paragraph}
              </p>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="mt-8 pt-6 border-t border-gray-100">
          <div className="flex items-center text-sm text-gray-500">
            <img
              src={`https://api.dicebear.com/7.x/initials/svg?seed=${recipe.createdBy.name}`}
              alt={recipe.createdBy.name}
              className="h-8 w-8 rounded-full mr-2"
            />
            <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-2">
              <span>Créé par {recipe.createdBy.name}</span>
              {recipe.createdAt && (
                <span className="text-gray-400">
                  le {new Date(recipe.createdAt).toLocaleDateString()}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}