import React, { forwardRef, useEffect } from 'react';
import { Search } from 'lucide-react';
import { useIngredientSearchStore } from '../../store/useIngredientSearchStore';
import { StandardIngredient } from '../../types/ingredients';
import { useTranslation } from 'react-i18next';

interface IngredientSearchInputProps {
  onSelect: (ingredient: StandardIngredient) => void;
  onClose: () => void;
}

export const IngredientSearchInput = forwardRef<HTMLInputElement, IngredientSearchInputProps>(
  ({ onSelect, onClose }, ref) => {
    const { t } = useTranslation();
    const {
      searchQuery,
      selectedIndex,
      setSearchQuery,
      setSelectedIndex,
      getFilteredIngredients,
      initializeIngredients,
      reset
    } = useIngredientSearchStore();

    const filteredIngredients = getFilteredIngredients();

    useEffect(() => {
      const unsubscribe = initializeIngredients();
      
      // Reset search state when component unmounts
      return () => {
        unsubscribe();
        reset();
      };
    }, [initializeIngredients, reset]);

    const handleKeyDown = (e: React.KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(Math.min(selectedIndex + 1, filteredIngredients.length - 1));
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(Math.max(selectedIndex - 1, -1));
          break;
        case 'Enter':
        case 'Tab':
          e.preventDefault();
          if (selectedIndex >= 0 && selectedIndex < filteredIngredients.length) {
            handleSelect(filteredIngredients[selectedIndex]);
          } else if (filteredIngredients.length > 0) {
            handleSelect(filteredIngredients[0]);
          }
          break;
        case 'Escape':
          e.preventDefault();
          onClose();
          break;
      }
    };

    const handleSelect = (ingredient: StandardIngredient) => {
      onSelect(ingredient);
      reset();
    };

    const highlightMatch = (text: string) => {
      if (!searchQuery) return text;

      const parts = text.split(new RegExp(`(${searchQuery})`, 'gi'));
      return parts.map((part, i) => 
        part.toLowerCase() === searchQuery.toLowerCase() ? 
          <span key={i} className="bg-menioo-green/10 text-menioo-green">{part}</span> : 
          part
      );
    };

    return (
      <div className="w-full">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
          <input
            ref={ref}
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full h-10 pl-10 pr-4 border-0 focus:ring-0 focus:outline-none"
            placeholder={t('Chercher les ingrédients')}
          />
        </div>

        {filteredIngredients.length > 0 && (
          <ul className="max-h-60 overflow-y-auto border-t border-gray-100">
            {filteredIngredients.map((ingredient, index) => (
              <li
                key={ingredient.id}
                onClick={() => handleSelect(ingredient)}
                onMouseEnter={() => setSelectedIndex(index)}
                className={`px-4 py-2 cursor-pointer transition-colors ${
                  index === selectedIndex ? 'bg-menioo-green/10' : 'hover:bg-gray-50'
                }`}
              >
                <div className="font-medium">
                  {highlightMatch(ingredient.name)}
                </div>
                {ingredient.aliases?.length > 0 && (
                  <div className="text-sm text-gray-500">
                    {ingredient.aliases.map((alias, i) => (
                      <span key={i}>
                        {i > 0 && ', '}
                        {highlightMatch(alias)}
                      </span>
                    ))}
                  </div>
                )}
                <div className="text-xs text-gray-400 mt-1">
                  {t(`categories.${ingredient.category}`)} • {t(`units.${ingredient.preferredUnit}`)}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

IngredientSearchInput.displayName = 'IngredientSearchInput';