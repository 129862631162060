import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Search, PlusCircle } from 'lucide-react';
import { useCookbookStore } from '../../store/useCookbookStore';

interface SubNavigationProps {
  activeView?: 'latest' | 'showcase' | 'popular';
  onViewChange?: (view: 'latest' | 'showcase' | 'popular') => void;
}

export function SubNavigation({ activeView = 'latest', onViewChange }: SubNavigationProps) {
  const location = useLocation();
  const setSearchTerm = useCookbookStore((state) => state.setSearchTerm);

  if (location.pathname !== '/recipes' && location.pathname !== '/cookbook') return null;

  if (location.pathname === '/cookbook') {
    return (
      <div className="bg-white border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between py-4">
            <div className="relative flex-1 max-w-lg">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="search"
                placeholder="Search recipes..."
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-menioo-green focus:border-menioo-green sm:text-sm"
              />
            </div>
            <Link
              to="/recipes/new"
              className="ml-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-menioo-orange hover:bg-menioo-orange-dark transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-menioo-orange"
            >
              <PlusCircle className="h-5 w-5 mr-2" />
              Ajouter une recette
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white border-t border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex space-x-8">
          <button
            onClick={() => onViewChange?.('latest')}
            className={`px-3 py-4 text-sm font-medium border-b-2 transition-colors ${
              activeView === 'latest'
                ? 'text-menioo-green border-menioo-green'
                : 'text-gray-500 border-transparent hover:text-menioo-navy hover:border-gray-300'
            }`}
          >
            Dernières recettes
          </button>
          <button
            onClick={() => onViewChange?.('showcase')}
            className={`px-3 py-4 text-sm font-medium border-b-2 transition-colors ${
              activeView === 'showcase'
                ? 'text-menioo-green border-menioo-green'
                : 'text-gray-500 border-transparent hover:text-menioo-navy hover:border-gray-300'
            }`}
          >
            En ce moment
          </button>
          <button
            onClick={() => onViewChange?.('popular')}
            className={`px-3 py-4 text-sm font-medium border-b-2 transition-colors ${
              activeView === 'popular'
                ? 'text-menioo-green border-menioo-green'
                : 'text-gray-500 border-transparent hover:text-menioo-navy hover:border-gray-300'
            }`}
          >
            Recettes préférées
          </button>
        </div>
      </div>
    </div>
  );
}
