import React, { useState } from 'react';
import { Clock, Users, CalendarPlus } from 'lucide-react';
import { Recipe } from '../../types';
import { Draggable } from '@hello-pangea/dnd';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { useAuthStore } from '../../store/useAuthStore';
import { addRecipeToMealPlanAndGrocery } from '../../utils/mealPlanUtils';
import { useNavigate } from 'react-router-dom';
import { format, addDays, startOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';

import { MealTypeModal } from './MealTypeModal';


interface DraggableRecipeProps {
  recipe: Recipe;
  index: number;
  onQuickAdd: (recipe: Recipe) => void;
}

export function DraggableRecipe({
  recipe,
  index,
  onQuickAdd
}: DraggableRecipeProps) {
  const navigate = useNavigate();
  const currentUser = useAuthStore((state) => state.currentUser);
  const [showMealTypeModal, setShowMealTypeModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');

  const handleQuickAdd = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMealTypeModal(true);
  };

  const handleCardClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('button')) {
      navigate(`/recipes/${recipe.id}`);
    }
  };

  const handleMealTypeSelect = async (mealType: string) => {
    if (!currentUser?.defaultOrganizationId || !selectedDate) return;

    try {
      await addRecipeToMealPlanAndGrocery(
        recipe,
        selectedDate,
        mealType,
        currentUser.id,
        currentUser.defaultOrganizationId
      );
      setShowMealTypeModal(false);
    } catch (error) {
      console.error('Error adding recipe:', error);
    }
  };

  // Generate dates for the next 14 days
  const dates = React.useMemo(() => {
    const startDate = startOfWeek(new Date(), { locale: fr });
    return Array.from({ length: 14 }, (_, i) => {
      const date = addDays(startDate, i);
      return {
        value: format(date, 'yyyy-MM-dd'),
        label: format(date, 'EEEE d MMMM', { locale: fr })
      };
    });
  }, []);

  return (
    <>
      <Draggable draggableId={recipe.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={handleCardClick}
            className="relative group rounded-lg overflow-hidden shadow hover:shadow-lg transition-all duration-200 bg-white cursor-pointer"
            style={{
              ...provided.draggableProps.style,
              opacity: snapshot.isDragging ? 0.5 : 1,
            }}
          >
            <div className="block relative aspect-[4/3] w-full">
              {recipe.image ? (
                <img
                  src={recipe.image}
                  alt={recipe.name}
                  className="w-full h-full object-cover rounded-t-lg"
                />
              ) : (
                <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">No image</span>
                </div>
              )}

              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent pointer-events-none" />

              <div className="absolute bottom-0 p-4 text-white">
                <h3 className="text-sm md:text-base font-semibold mb-1 line-clamp-2">
                  {recipe.name}
                </h3>
                <div className="flex items-center space-x-2 text-xs md:text-sm">
                  <div className="flex items-center">
                    <Clock className="h-4 w-4 mr-1" />
                    <span>{recipe.prepTime + recipe.cookTime} mins</span>
                  </div>
                  <div className="flex items-center">
                    <Users className="h-4 w-4 mr-1" />
                    <span>{recipe.servings}</span>
                  </div>
                </div>
              </div>

              <div 
                className="absolute top-2 right-2 z-20"
                onClick={e => e.stopPropagation()}
              >
                <button
                  onClick={handleQuickAdd}
                  className="bg-white p-2 rounded-full shadow-lg hover:bg-green-500 hover:text-white transition group-hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  title="Add to meal plan"
                >
                  <CalendarPlus className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        )}
      </Draggable>

      {showMealTypeModal && (
        <MealTypeModal
          recipe={recipe}
          isOpen={showMealTypeModal}
          onClose={() => setShowMealTypeModal(false)}
          onSelect={handleMealTypeSelect}
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
          source="draggable"
          dates={dates}
          onSkip={async () => {
            if (!currentUser?.defaultOrganizationId || !selectedDate) return;
            try {
              await addRecipeToMealPlanAndGrocery(
                recipe,
                selectedDate,
                '', // Empty meal type
                currentUser.id,
                currentUser.defaultOrganizationId
              );
              setShowMealTypeModal(false);
            } catch (error) {
              console.error('Error adding recipe:', error);
            }
          }}
        />
      )}
    </>
  );
}