import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { MainNavigation } from './MainNavigation';
import { SubNavigation } from './SubNavigation';

export function MainLayout() {
  const location = useLocation();

  // State for active view (latest, showcase, popular)
  const [activeView, setActiveView] = useState<'latest' | 'showcase' | 'popular'>('latest');

  // Determine whether to show SubNavigation
  const showSubNavigation = location.pathname === '/recipes' || location.pathname === '/cookbook';

  return (
    <div className="min-h-screen bg-menioo-cream">
      <MainNavigation />

      {/* Pass activeView and setActiveView to SubNavigation */}
      {showSubNavigation && (
        <SubNavigation activeView={activeView} onViewChange={setActiveView} />
      )}

      <main className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4">
        <div className="flex gap-6">
          {/* Main Content */}
          <div className="flex-1">
            {/* Pass activeView to the Outlet for dynamic child components */}
            <Outlet context={{ activeView, setActiveView }} />
          </div>
        </div>
      </main>

      <footer className="bg-menioo-navy text-white py-6 mt-8">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
          <div className="flex justify-between items-center">
            <div className="text-sm">
              © {new Date().getFullYear()} Menioo. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
