import React from 'react';
import { ChefHat } from 'lucide-react';
import { DiscoveryViews } from './DiscoveryViews';
import { useOutletContext } from 'react-router-dom';

export function PublicRecipes() {
  // Access activeView and setActiveView from the Outlet context
  const { activeView } = useOutletContext<{
    activeView: 'latest' | 'showcase' | 'popular';
  }>();

  return (
    <div>
      <div className="mt-1">
        {/* Pass the activeView to DiscoveryViews */}
        <DiscoveryViews activeView={activeView} />
      </div>
    </div>
  );
}
